<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>

    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="fieald"
      :rows="products"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,
  BSpinner,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onMounted } from '@vue/composition-api'
import store from '@/store/index'
import useProduct from '../products/useProduct'
import axiosIns from '@/libs/axios'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      dir: false,
      searchTerm: '',
      fieald: [
        {
          label: 'Item code',
          field: 'item_code',
          filterOptions: {
            enabled: true,
            placeholder: 'Item code',
          },
        },

        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Description',
          },
        },
        {
          label: 'EAN',
          field: 'ean',
          filterOptions: {
            enabled: true,
            placeholder: 'EAN',
          },
        },
        {
          label: 'PCS',
          field: 'stock_received_brazil',
          filterOptions: {
            enabled: true,
            placeholder: 'Stock',
          },
        },

      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    async deleteClient(devid) {
      const result = await this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes I want to delete this product',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      this.load = 'true'
      if (result.value) {
        try {
          await axiosIns.delete(`/products/delete/${devid}/`)
          this.load = 'false'
          this.getProducts()
          this.showToast('success', 'top-center', 'Product successfully deleted')
        } catch (error) {
          this.load = 'false'
          this.showToast('danger', 'top-center', error.toString())
        }
      } else if (result.dismiss === 'cancel') {
        this.load = 'false'
        this.$swal({
          title: 'Close',
          text: 'Product Not Deleted',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
  setup() {
    const { products, getProducts, columns } = useProduct()

    onMounted(() => {
      getProducts()
    })

    return {
      products,
      getProducts,
      columns,
    }
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
